.card-container{
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 2%;
}

.card-header{
    display: inline;
    font-weight: 400;
    margin-bottom: 2%;
}

.card-description{
    display: block;
    margin-top: 5%;
}

.card-img{
    object-fit: contain;
}