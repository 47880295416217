.brand-img{
    object-fit: contain;
    display: inline-block;
    margin-bottom: 3%;
}

.brand-img-container{
    margin-top: 3%;
    margin-bottom: 3%;
    padding-left: 2%;
    padding-right: 2%;
}