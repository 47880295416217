.jumbotron {
    background-color: #0A090C;
    /* background-image: url('../../assets/printer-shirts-bg.jpeg');
    background-size: cover; */
    position: relative;
    height: 100%;
    vertical-align: middle;
    height: 90vh;
}

.jumbotron::before {
    content: "";
    position: absolute;
    opacity: 0.15;
    top: 0; right: 0;
    bottom: 0; left: 0;
    background-color: #0A090C;
    /* background-image: url('../../assets/printer-shirts-bg.jpeg'); */
    background-image: url('../../assets/blue-embroidery-bg.jpeg');
    background-size: cover;
    height: 100%;
}

.jumbotron-img{
    object-fit: contain;
    display: inline-block;
    width: 85%;
}

.jumbotron-img-container{
    margin-right: 10%;
}

.jumbotron-cta-container {
    z-index: 1;
}