.footer-container{
    background-color: #2A272F;
    margin-top: 2%;
    /* padding-top: 2%; */
}

.footer-header-container{
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 1%;
}