.jumbotron-tagline {
    padding: 15%;
    color: #F7F7FF;
}

.jumbotron-tagline-btn{
    margin-right: 3%;
}

.jumbotron-tagline-header-container{
    padding-bottom: 2%;
}